import React, { useEffect, useState } from 'react';
import type { FC } from 'react';
import { useLocation } from '@reach/router';
import { addMinutes, format, formatISO, parseISO } from 'date-fns';

import {
  Box,
  Flex,
  Heading,
  Link,
  SimpleGrid,
  Stack,
  Text,
} from '@chakra-ui/core';
import {
  FaApple,
  FaGoogle,
  FaMicrosoft,
  FaRegCalendarAlt,
  FaRegClock,
  FaUniversity,
  FaYahoo,
} from 'react-icons/fa';
import type { IconType } from 'react-icons';

import EinsteinStudiosLogoUpright from 'assets/svgs/einstein-studios-logo-upright.svg';
import { IconButtonChakraLink } from 'components/_lib';
import { withSEO } from 'components/_hoc';
import { parseQueryString } from 'utils';

/*
 * === TYPES ===
 */

// Enumeration of calendar format options
enum CalendarTypes {
  Google,
  Yahoo,
  Ics,
}

// User add-to-calendar options

interface CalendarOption {
  name: string;
  label: string;
  icon: IconType;
  type: CalendarTypes;
}

/*
 * === CONSTANTS ===
 */

const options: CalendarOption[] = [
  {
    name: 'Apple Calendar',
    label: 'add to apple calendar',
    icon: FaApple,
    type: CalendarTypes.Ics,
  },
  {
    name: 'Google Calendar',
    label: 'add to google calendar',
    icon: FaGoogle,
    type: CalendarTypes.Google,
  },
  {
    name: 'Microsoft Outlook',
    label: 'add to microsoft outlook',
    icon: FaMicrosoft,
    type: CalendarTypes.Ics,
  },
  {
    name: 'Yahoo! Calendar',
    label: 'add to yahoo calendar',
    icon: FaYahoo,
    type: CalendarTypes.Yahoo,
  },
];

/*
 * === HELPERS ===
 */

/**
 * Formats a datetime into calendar-link-compatible ISO string format.
 *
 * @param datetime - Datetime to format.
 *
 * @retuns - Formatted datetime ISO string.
 */
const formatDatetime = (datetime: Date): string =>
  formatISO(datetime, { format: 'basic' }).split('-')[0];

interface BuildAddToCalendarLinkParams {
  title: string;
  datetime: Date;
  durationInMinutes: number;
  description: string;
}

/**
 * Builds an add-to-calendar URL of a given type.
 *
 * @param event - Event to create add-to-calendar url for.
 * @param calendar - Type of calendar to build url for.
 *
 * @returns - Add-to-calendar URL for the given event.
 */
const buildAddToCalendarUrl = (
  event: BuildAddToCalendarLinkParams,
  calendar: CalendarTypes
): string => {
  const start = event.datetime;
  const end = addMinutes(start, event.durationInMinutes);

  let url = '';
  switch (calendar) {
    case CalendarTypes.Google:
      url = 'https://calendar.google.com/calendar/r/eventedit';
      url += `?text=${encodeURIComponent(event.title)}`;
      url += `&dates=${formatDatetime(start)}`;
      url += `/${formatDatetime(end)}`;
      url += `&details=${encodeURIComponent(event.description)}`;
      break;

    case CalendarTypes.Yahoo:
      url = 'https://calendar.yahoo.com?v=60&type=20';
      url += `&title=${encodeURIComponent(event.title)}`;
      url += `&st=${formatDatetime(start)}`;
      url += `&dur=00${event.durationInMinutes}`;
      url += `&desc=${encodeURIComponent(event.description)}`;
      break;

    default: {
      const description = (event.description || '')
        .replace(/\n/gm, '\\n')
        .replace(/(\\n)\s+/gm, '\\n');

      const ics = [
        'BEGIN:VCALENDAR',
        'VERSION:2.0',
        'BEGIN:VEVENT',
        `DTSTART:${formatDatetime(start)}`,
        `DTEND:${formatDatetime(end)}`,
        `SUMMARY:${event.title}`,
        `DESCRIPTION:${description}`,
        'END:VEVENT',
        'END:VCALENDAR',
      ].join('\n');

      url = encodeURI(`data:text/calendar;charset=utf8,${ics}`);
    }
  }

  return url;
};

/*
 * === COMPONENTS ===
 */

// AddToCalendarButton component prop types
type AddToCalendarButtonProps = BuildAddToCalendarLinkParams & CalendarOption;

/**
 * Reusable add-to-calendar button for a given event and calendar type
 */
const AddToCalendarButton: FC<AddToCalendarButtonProps> = ({
  name,
  label,
  icon,
  type,
  ...event
}: AddToCalendarButtonProps) => (
  <Stack align="center" w="100px">
    <IconButtonChakraLink
      variant="ghost"
      variantColor="orange"
      isRound
      h="5rem"
      w="5rem"
      aria-label={label}
      icon={() => <Box as={icon} w="2.5rem" h="2.5rem" />}
      href={buildAddToCalendarUrl(event, type)}
    />
    <Text>{name}</Text>
  </Stack>
);

/**
 * Add a given event to your personal calendar
 */
const AddToCalendarPage: FC = () => {
  const { search } = useLocation();
  const [event, setEvent] = useState<BuildAddToCalendarLinkParams | null>();

  useEffect(() => {
    const title = parseQueryString('title', search);
    const datetime = parseQueryString('datetime', search);
    const durationInMinutes = parseQueryString('duration_in_minutes', search);
    const description = parseQueryString('description', search);

    setEvent(
      title && datetime && durationInMinutes && description
        ? {
            title,
            datetime: parseISO(datetime),
            durationInMinutes: +durationInMinutes,
            description,
          }
        : null
    );
  }, [search, setEvent]);

  return event === undefined ? null : (
    <Flex minH="100vh" align="center" justify="center">
      <Stack
        w={['90%', '80%', '70%', '60%']}
        maxW="850px"
        my={[4, 8, 12, 16]}
        px={[8, 16, 24, 32]}
        py={16}
        spacing={8}
        rounded="lg"
        bg="rgba(216, 216, 216, 0.2)"
        border="1px solid rgb(151, 151, 151, 0.2)"
        boxShadow="lg"
        align="center"
        justify="center"
        textAlign="center"
        color="teal.500"
      >
        <Link href={process.env.LANDING_PAGE_URL} w="100px">
          <EinsteinStudiosLogoUpright />
        </Link>
        <Heading size="md">
          {event === null
            ? 'Invalid event details. Please check your link and try again.'
            : 'Add your upcoming class to your personal calendar:'}
        </Heading>
        {event !== null && (
          <Stack align="center" spacing={8}>
            <Stack align="center">
              <Stack isInline align="center">
                <Box as={FaUniversity} mr={2} w="1.25rem" h="1.25rem" />
                <Text>{event.title}</Text>
              </Stack>
              <Stack isInline align="center">
                <Box as={FaRegCalendarAlt} mr={2} w="1.25rem" h="1.25rem" />
                <Text>{format(event.datetime, "P 'at' p")}</Text>
              </Stack>
              <Stack isInline align="center">
                <Box as={FaRegClock} mr={2} w="1.25rem" h="1.25rem" />
                <Text>{event.durationInMinutes} minutes</Text>
              </Stack>
            </Stack>
            <SimpleGrid columns={[2, null, 4]} spacing={4}>
              {options.map((option) => (
                <AddToCalendarButton key={option.name} {...option} {...event} />
              ))}
            </SimpleGrid>
          </Stack>
        )}
      </Stack>
    </Flex>
  );
};

export default withSEO(AddToCalendarPage, {
  title: 'Add to Calendar',
  description:
    'Add your upcoming class with Einstein Studios to your personal Google, Yahoo!, Outlook or Apple calendar.',
});
